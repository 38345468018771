import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { withPreview } from "gatsby-source-prismic";

// Components
import { Squiggle } from "../components/icons/squiggle";

const Page = styled.div`
  margin: 150px 0 78px 0;
  padding: 0 40px;

  @media (max-width: 900px) {
    margin: 76px 0 0 0;
  }

  @media (max-width: 767px) {
    padding: 0 40px 0 20px;
  }

  & a:hover {
    color: #d7490c;
  }
`;

const ColumnSpan6 = styled.div`
  position: relative;

  grid-column: span 6;

  @media (max-width: 767px) {
    grid-column: 1;
    order: ${props => props.orderMobile};
  }
`;

const ColumnSpan3 = styled.div`
  position: relative;

  grid-column: span 3;

  ${props =>
    props.alignItems &&
    ` display: flex;
      align-items: center;
  `};

  @media (max-width: 1000px) {
    grid-column: ${props => (props.spanTablet ? props.spanTablet : `span 3`)};
  }

  @media (max-width: 767px) {
    grid-column: 1;
    order: ${props => props.orderMobile};
  }
`;

const ColumnSpan2 = styled.div`
  position: relative;

  grid-column-start: 2;
  grid-column-end: 4;

  @media (max-width: 1000px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media (max-width: 767px) {
    grid-column-start: 1;
    grid-column-end: 2;

    order: ${props => props.orderMobile};

    margin-bottom: calc(40px - 1em);
  }
`;

const Grid = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: ${props => props.grid};
  grid-column-gap: 40px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const QuoteWrapper = styled.div`
  & .quote-wrapper:nth-of-type(4n + 1) {
    margin: 105px 0;

    @media (max-width: 767px) {
      margin: 0 0 40px 0;
    }
  }

  & .quote-wrapper:nth-of-type(4n + 2) {
    margin: 105px 0 105px 33%;

    & .quote-inner {
      margin: 0 auto;
    }

    @media (max-width: 1000px) {
      margin: 105px 0 105px 10%;
    }

    @media (max-width: 767px) {
      margin: 40px 0;
    }
  }

  & .quote-wrapper:nth-of-type(4n + 3),
  & .quote-wrapper:nth-of-type(4n + 5) {
    margin: 105px 0 105px 16%;

    @media (max-width: 1000px) {
      margin: 105px 0 105px 0;
    }
    @media (max-width: 767px) {
      margin: 40px 0;
    }
  }

  & .quote-wrapper:nth-of-type(4n + 4) {
    margin: 105px 40px 105px 50%;

    & .quote-inner {
      margin: 0 0 0 auto;
    }

    @media (max-width: 1000px) {
      margin: 105px 40px 105px 20%;
    }

    @media (max-width: 767px) {
      margin: 40px 0;
    }
  }
`;

const AboutText = styled.div`
  width: 100%;
  max-width: 567px;

  margin: 0 0 90px 0;

  font-size: 23px;
  line-height: 34px;
  letter-spacing: 0.06em;

  & p:first-of-type {
    margin-top: 0;
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 1000px) {
    font-size: 20px;
    line-height: 28px;

    max-width: 493px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;

    max-width: 100%;

    margin: 0 0 40px 0;
  }

  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 22px;
  }
`;

const ClientText = styled.div`
  margin: 0 0 90px 0;
  padding: 0 20px 0 0;

  & h3 {
    color: #d7490c;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.15em;

    text-transform: uppercase;
  }

  & h3,
  & p {
    margin: 0;
  }

  & p {
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.06em;

    @media (max-width: 1000px) {
      font-size: 20px;
      line-height: 28px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 22px;
    }
  }

  @media (max-width: 1000px) {
    padding: 0;
  }

  @media (max-width: 767px) {
    margin: 0 0 40px 0;
  }
`;

const SmallText = styled.div`
  margin: 0 0 1em 0;

  & h3 {
    color: #d7490c;
    text-transform: uppercase;
  }

  & h3 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.15em;

    margin: 0;
  }

  & p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.06em;

    margin: 0;
  }
`;

const QuoteText = styled.div`
  max-width: 590px;

  @media (max-width: 1000px) {
    max-width: 560px;
  }

  & p:first-of-type {
    margin-top: 0;
  }

  & .quote-text {
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.06em;

    & p:last-of-type {
      margin-bottom: 13px;
    }

    @media (max-width: 1000px) {
      font-size: 20px;
      line-height: 28px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: 22px;
    }
  }

  & .quote-title {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.06em;
  }
`;

const ContactText = styled.div`
  margin: 0 0 115px 0;

  @media (max-width: 767px) {
    margin: 0 0 40px 0;
  }
`;

const OneLiner = styled.div`
  position: fixed;
  bottom: 23px;
  right: 40px;

  transform: rotate(90deg);
  transform-origin: right;

  width: max-content;

  @media (max-width: 767px) {
    right: 20px;
    bottom: 10px;
  }

  & h2 {
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 0.06em;

    font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande",
      sans-serif;

    color: #d7490c;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
`;

const Index = ({ data }) => {
  const repeatableText = data.prismicHomepage.data.text.map(
    (content, index) => (
      <SmallText key={`repeatable_text_${index}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: content.repeatable_title.html,
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: content.repeatable_text.html,
          }}
        />
      </SmallText>
    )
  );

  const quoteText = data.prismicHomepage.data.quotes.map((content, index) => (
    <div key={`quote_text_${index}`} className="quote-wrapper">
      <QuoteText className="quote-inner">
        <div
          className="quote-text"
          dangerouslySetInnerHTML={{
            __html: content.quote_text.html,
          }}
        />
        <div
          className="quote-title"
          dangerouslySetInnerHTML={{
            __html: content.quote_title.html,
          }}
        />
      </QuoteText>
    </div>
  ));

  return (
    <Page>
      <Helmet title={`The Seeking State`} />
      <Grid gridDesktop={`repeat(6, 1fr)`} gridTablet={`repeat(4, 1fr)`}>
        <ColumnSpan6 orderMobile={1}>
          <AboutText
            dangerouslySetInnerHTML={{
              __html: data.prismicHomepage.data.about_text.html,
            }}
          />
          <Squiggle />
        </ColumnSpan6>
        <ColumnSpan3 spanTablet={`span 2`} orderMobile={3} alignItems={true}>
          <Grid gridDesktop={`repeat(3, 1fr)`}>
            <ColumnSpan2>{repeatableText}</ColumnSpan2>
          </Grid>
        </ColumnSpan3>
        <ColumnSpan3 spanTablet={`span 4`} orderMobile={2}>
          <ClientText>
            <h3>Clients</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicHomepage.data.clients_text.html,
              }}
            />
          </ClientText>

          <OneLiner
            dangerouslySetInnerHTML={{
              __html: data.prismicHomepage.data.one_liner.html,
            }}
          />
        </ColumnSpan3>
        <ColumnSpan3 orderMobile={4}>
          <Grid gridDesktop={`repeat(3, 1fr)`} gridTablet={`repeat(2, 1fr)`}>
            <ColumnSpan2>
              <SmallText>
                <ContactText>
                  <h3>Contact</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.prismicHomepage.data.contact_text.html,
                    }}
                  />
                </ContactText>
              </SmallText>
            </ColumnSpan2>
          </Grid>
        </ColumnSpan3>

        <ColumnSpan6 orderMobile={5}>
          <QuoteWrapper>{quoteText}</QuoteWrapper>
        </ColumnSpan6>
      </Grid>
    </Page>
  );
};

export default withPreview(Index);

export const query = graphql`
  {
    prismicHomepage {
      data {
        about_text {
          html
        }
        text {
          repeatable_text {
            html
          }
          repeatable_title {
            html
          }
        }
        one_liner {
          html
        }
        contact_text {
          html
        }
        clients_text {
          html
        }
        quotes {
          quote_text {
            html
          }
          quote_title {
            html
          }
        }
      }
    }
  }
`;
